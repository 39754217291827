import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { HottestLabTextLogo } from '../ui/SvgSet';

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* background-color: #ffffff; */
`;

const ContentContainer = styled.div`
    width: calc(100% - 32px);
    padding: 16px;
    @media only screen and (min-width: 480px) {
        width: calc(100% - 32px);
        padding: 32px 16px;
    }
    @media only screen and (min-width: 1200px) {
        width: calc(100% - 112px);
        padding: 56px;
    }
    /* @media only screen and (min-width: 940px) {
        width: 940px;
    } */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const LogoContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    :hover {
        cursor: pointer;
    }
`;

const LogoImage = styled.img`
    width: 40px;
    @media only screen and (min-width: 1200px) {
        width: 56px;
    }
    margin-right: 8px;
`;

const MenuContainer = styled.div`
    display: none;
    @media only screen and (min-width: 940px) {
        display: flex;
    }
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

const MenuItem = styled.div`
    font-family: 'Montserrat', sans-serif;
    padding: 16px;
    margin: 0px 16px;
    font-size: 16px;
    font-weight: 600;
    color: #ffffff;
    text-transform: capitalize;
    :hover {
        cursor: pointer;
        color: #ff005c;
    }
    -webkit-transition: -webkit-color 0.2s ease-in-out;
    transition: color 0.2s ease-in-out;
`;

const CustomNavLink = styled(NavLink)`
    font-family: 'Montserrat', sans-serif;
    padding: 16px;
    margin: 0px 16px;
    font-size: 16px;
    font-weight: 600;
    color: #ffffff;
    text-transform: capitalize;
    :hover {
        cursor: pointer;
        color: #ff005c;
    }
    -webkit-transition: -webkit-color 0.2s ease-in-out;
    transition: color 0.2s ease-in-out;
    text-decoration: none;
`;

function Header(props) {
    const [hash, setHash] = useState(window.location.hash.replaceAll('#', ''));

    useEffect(() => {
        if (hash.length > 0) {
            window.location.hash = `#${hash}`;
            scrollTo(`inner-section-${hash}`);
        }
    }, [hash]);

    const scrollTo = (targetElemId) => {
        const targetElem = document.querySelector(`#${targetElemId}`);
        if (targetElem) {
            const targetOffsetTop = targetElem.offsetTop;

            window.scrollTo({
                top: targetOffsetTop,
                behavior: 'smooth',
            });
        }
    };

    const goHomeWithHash = (hash) => {
        if (window.location.pathname === '/') {
            setHash(hash);
        } else {
            window.location = `/#${hash}`;
        }
    };

    return (
        <Wrapper>
            <ContentContainer>
                <LogoContainer
                    onClick={() => {
                        window.location = '/';
                    }}>
                    <LogoImage src="/logo192.png" />
                    <HottestLabTextLogo
                        width={224}
                        height={27}
                        fill={'#ffffff'}
                    />
                </LogoContainer>

                <MenuContainer>
                    {false && (
                        <MenuItem
                            onClick={() => {
                                goHomeWithHash('home');
                            }}>
                            Home
                        </MenuItem>
                    )}
                    <MenuItem
                        onClick={() => {
                            goHomeWithHash('about');
                        }}>
                        About
                    </MenuItem>
                    {/* <MenuItem
                        onClick={() => {
                            goHomeWithHash('games');
                        }}>
                        Games
                    </MenuItem> */}
                    <MenuItem
                        onClick={() => {
                            goHomeWithHash('services');
                        }}>
                        Services
                    </MenuItem>
                    <CustomNavLink to="/terms">Terms</CustomNavLink>
                    <CustomNavLink to="/privacy">Privacy</CustomNavLink>
                    <CustomNavLink to="/contact">Contact</CustomNavLink>
                </MenuContainer>
            </ContentContainer>
        </Wrapper>
    );
}

export default Header;
