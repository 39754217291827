import React from 'react';
import styled from 'styled-components';
import { GridContainer } from '../base/Grid';
import ServiceItem from '../ui/ServiceItem';
import GameItem from '../ui/GameItem';
import GameDict from '../../constant/GameDict';

const Section = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: ${(props) => props.background || 'transparent'};
`;

const InnerSection = styled.div`
    @media only screen and (min-width: 1200px) {
        width: 1260px;
        min-height: ${(props) => props.height}px;
    }
    padding: 16px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
`;

const SectionTextContainer = styled.div`
    width: calc(100% - 32px);
    padding: 56px 16px;
    @media only screen and (min-width: 480px) {
        width: calc(100% - 32px);
        padding: 56px 16px;
    }
    @media only screen and (min-width: 940px) {
        width: calc(50%);
        padding: 56px 0px;
    }
    @media only screen and (min-width: 1200px) {
        width: calc(50%);
        padding: 56px 0px;
    }
`;

const SectionTitle = styled.div`
    font-size: 56px;
    font-weight: bold;
`;

const SectionDescription = styled.div`
    margin-top: 16px;
    font-size: 24px;
    color: #9b9b9b;
    line-height: 40px;
    white-space: pre-wrap;
`;

const TitleText = styled.h1`
    font-size: 56px;
    line-height: 60px;
    @media only screen and (min-width: 480px) {
        font-size: 10vw;
        line-height: 10vw;
    }
    @media only screen and (min-width: 1200px) {
        font-size: 128px;
        line-height: 120px;
    }
    font-weight: bold;
    text-transform: uppercase;
    background: linear-gradient(135deg, #ff005c, #ff1f1f);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    white-space: pre-wrap;
`;

const AboutImage = styled.img`
    width: calc(100% - 32px);
    margin: 16px;
    @media only screen and (min-width: 480px) {
        width: calc(100% - 32px);
        margin-right: 56px;
    }
    @media only screen and (min-width: 940px) {
        width: calc(50% - 112px);
        margin-right: 56px;
    }
    @media only screen and (min-width: 1200px) {
        width: calc(50% - 112px);
        margin-top: -160px;
        /* margin-right: 56px; */
    }
    /* max-height: 700px;
    object-fit: cover; */
    border-radius: 16px;
`;

const GameSectionContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
`;

const GameItemContainer = styled(GridContainer)`
    padding: 16px 0px;
`;

function IndexPage() {
    return (
        <>
            {/* Home */}
            <Section>
                <InnerSection id={'inner-section-home'} height={900}>
                    <TitleText>{'The hottest\nservices ever!'}</TitleText>
                </InnerSection>
            </Section>

            {/* About */}
            {false && (
                <Section background={'#ffffff'}>
                    <InnerSection id={'inner-section-about'} height={800}>
                        <AboutImage src={'/about_image.jpg'} />

                        <SectionTextContainer>
                            <SectionTitle>About</SectionTitle>
                            <SectionDescription>
                                {
                                    "People meet a lot of people in their lives.\nThe process of meeting new people and making memories that they share is the biggest part of life.\n\nSome people are given many opportunities to meet new people, and others are not.\nIt's also very difficult for someone to discover new people.\n\nTo prevent people from being left out,\nwe are trying to use IT technology to help everyone meet new people easily."
                                }
                            </SectionDescription>
                        </SectionTextContainer>
                    </InnerSection>
                </Section>
            )}
            <Section background={'#ffffff'}>
                <InnerSection id={'inner-section-about'} height={800}>
                    <AboutImage src={'/about_image-new.jpg'} />

                    <SectionTextContainer>
                        <SectionTitle>About</SectionTitle>
                        <SectionDescription>
                            {
                                'We are making the hottest services on the planet.'
                            }
                        </SectionDescription>
                    </SectionTextContainer>
                </InnerSection>
            </Section>

            {/* Services */}
            <Section background={'#f7f7f7'}>
                <InnerSection id={'inner-section-services'} height={800}>
                    <GameSectionContainer>
                        <SectionTitle>Services</SectionTitle>
                        {/* <SectionDescription>
                            Enjoy our games now!
                        </SectionDescription> */}

                        <GameItemContainer spacing={16}>
                            <ServiceItem
                                title="MarkSlides"
                                imgSrc="./markslides.png"
                                serviceUrl="https://www.markslides.ai/"
                            />

                            {/* {Object.values(GameDict).map((game) => {
                                return (
                                    <GameItem
                                        key={game.key}
                                        linkKey={game.key}
                                        title={game.title}
                                        imgSrc={game.imgSrc}
                                        appStoreUrl={game.appStoreUrl}
                                        googlePlayUrl={game.googlePlayUrl}
                                    />
                                );
                            })} */}
                        </GameItemContainer>
                    </GameSectionContainer>
                </InnerSection>
            </Section>
        </>
    );
}

export default IndexPage;
