import React from 'react';
import styled from 'styled-components';
import { GridItem } from '../base/Grid';

const Wrapper = styled(GridItem)`
    position: relative;
    /* height: 400px; */
`;

const Title = styled.div`
    font-size: 1.6rem;
    font-weight: bold;
    color: #333333;
`;

const CoverImage = styled.img`
    width: 100%;
    height: auto;
    /* max-height: 512px; */
    margin-top: 16px;
    /* object-fit: cover; */
    border-radius: 16px;
`;

function ServiceItem(props) {
    const { title, imgSrc, serviceUrl } = props;

    return (
        <Wrapper xs={12} sm={12} md={6} lg={4} xl={4}>
            <Title>{title}</Title>

            <a target="_blank" rel="noreferrer" href={serviceUrl}>
                <CoverImage src={imgSrc} />
            </a>
        </Wrapper>
    );
}

export default ServiceItem;
