import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* background: #ffffff; */
`;

const InnerSection = styled.div`
    @media only screen and (min-width: 1200px) {
        width: 1260px;
        min-height: ${(props) => props.height}px;
    }
    padding: 16px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
`;

const SectionTextContainer = styled.div`
    width: calc(100% - 32px);
    padding: 56px 16px;
    /* @media only screen and (min-width: 480px) {
        width: calc(100% - 32px);
        padding: 56px 16px;
    }
    @media only screen and (min-width: 940px) {
        width: calc(50%);
        padding: 56px 0px;
    }
    @media only screen and (min-width: 1200px) {
        width: calc(50%);
        padding: 56px 0px;
    } */
`;

const TitleText = styled.div`
    font-size: 56px;
    font-weight: bold;
    color: #ffffff;
`;

const PrivacyText = styled.div`
    margin-top: 16px;
    font-size: 24px;
    color: #ffffff;
    /* line-height: 40px; */
    white-space: pre-wrap;
`;

function PrivacyPage() {
    return (
        <Wrapper>
            <InnerSection height={800}>
                <SectionTextContainer>
                    <TitleText>Privacy Policy</TitleText>
                    <PrivacyText>
                        <h2>Last revised on 03/23/2021</h2>

                        <h2 style={{ fontSize: 32 }}>
                            Welcome to Hottest Lab’s Privacy Policy. Thank you
                            for taking the time to read it.
                            <br />
                            <br />
                            We appreciate that you trust us with your
                            information and we intend to always keep that trust.
                            This starts with making sure you understand the
                            information we collect, why we collect it, how it is
                            used and your choices regarding your information.
                            This Policy describes our privacy practices in plain
                            language, keeping legal and technical jargon to a
                            minimum.
                        </h2>

                        <div style={{ fontSize: 24 }}>
                            <div>
                                <b>1. WHO WE ARE</b>
                                <br />
                                <span>
                                    The company that is responsible for your
                                    information under this Privacy Policy (the
                                    “data controller”) is:
                                    <br />
                                    <br />
                                    Hottest Lab Inc.
                                    <br />
                                    3F #58, 128, Teheran-ro
                                    <br />
                                    Gangnam-gu, Seoul
                                    <br />
                                    South Korea
                                </span>
                            </div>

                            <br />
                            <div>
                                <b>2. WHERE THIS PRIVACY POLICY APPLIES</b>
                                <br />
                                <span>
                                    This Privacy Policy applies to websites,
                                    apps, events and other services operated by
                                    Hottest Lab. For simplicity, we refer to all
                                    of these as our “services” in this Privacy
                                    Policy. To make it extra clear, we’ve added
                                    links to this Privacy Policy on all
                                    applicable services.
                                    <br />
                                    <br />
                                    Some services may require their own unique
                                    privacy policy. If a particular service has
                                    its own privacy policy, then that policy --
                                    not this Privacy Policy -- applies.
                                </span>
                            </div>

                            <br />
                            <div>
                                <b>3. INFORMATION WE COLLECT</b>
                                <br />
                                <span>
                                    It goes without saying, we can’t help you
                                    develop meaningful connections without some
                                    information about you, such as basic profile
                                    details and the types of people you’d like
                                    to meet. We also collect information
                                    generated as you use our services, for
                                    example access logs, as well as information
                                    from third parties, like when you access our
                                    services through a social media account. If
                                    you want additional info, we go into more
                                    detail below.
                                    <br />
                                    <br />
                                    Information you give us
                                    <br />
                                    You choose to give us certain information
                                    when using our services. This includes:
                                    <br />
                                    <br />
                                    When you create an account, you provide us
                                    with at least your login credentials, as
                                    well as some basic details necessary for the
                                    service to work, such as your gender and
                                    date of birth.
                                    <br />
                                    When you complete your profile, you can
                                    share with us additional information, such
                                    as height, body type, details on your
                                    personality, lifestyle, interests and other
                                    details about you, as well as content such
                                    as photos and videos. To add certain
                                    content, like pictures or videos, you may
                                    allow us to access your camera or photo
                                    album. Some of the information you choose to
                                    provide us may be considered “special” or
                                    “sensitive” in certain jurisdictions, for
                                    example your racial or ethnic origins,
                                    sexual orientation and religious beliefs. By
                                    choosing to provide this information, you
                                    consent to our processing of that
                                    information.
                                    <br />
                                    When you subscribe to a paid service or make
                                    a purchase directly from us (rather than
                                    through a platform such as iOS or Android),
                                    you provide us or our payment service
                                    provider with information, such as your
                                    debit or credit card number or other
                                    financial information.
                                    <br />
                                    When you participate in surveys or focus
                                    groups, you give us your insights into our
                                    products and services, responses to our
                                    questions and testimonials.
                                    <br />
                                    When you choose to participate in our
                                    promotions, events or contests, we collect
                                    the information that you use to register or
                                    enter.
                                    <br />
                                    If you contact our customer care team, we
                                    collect the information you give us during
                                    the interaction. Sometimes, we monitor or
                                    record these interactions for training
                                    purposes and to ensure a high quality of
                                    service.
                                    <br />
                                    If you ask us to communicate with or
                                    otherwise process information of other
                                    people (for example, if you ask us to send
                                    an email on your behalf to one of your
                                    friends), we collect the information about
                                    others that you give us in order to complete
                                    your request.
                                    <br />
                                    Of course, we also process your chats with
                                    other users as well as the content you
                                    publish, as part of the operation of the
                                    services.
                                    <br />
                                    <br />
                                    Information we receive from others
                                    <br />
                                    In addition to the information you provide
                                    us directly, we receive information about
                                    you from others, including:
                                    <br />
                                    <br />
                                    Other Users
                                    <br />
                                    Other users may provide information about
                                    you as they use our services. For instance,
                                    we may collect information about you from
                                    other users if they contact us about you.
                                    <br />
                                    <br />
                                    Social Media
                                    <br />
                                    You may be able to use your social media
                                    login (such as Facebook Login) to create and
                                    log into your Hottest Lab account. This saves
                                    you from having to remember yet another user
                                    name and password and allows you to share
                                    some information from your social media
                                    account with us.
                                    <br />
                                    <br />
                                    Other Partners
                                    <br />
                                    We may receive info about you from our
                                    partners, for instance where Hottest Lab ads
                                    are published on a partner’s websites and
                                    platforms (in which case they may pass along
                                    details on a campaign’s success).
                                    <br />
                                    <br />
                                    Information collected when you use our
                                    services
                                    <br />
                                    When you use our services, we collect
                                    information about which features you’ve
                                    used, how you’ve used them and the devices
                                    you use to access our services. See below
                                    for more details:
                                    <br />
                                    <br />
                                    Usage Information
                                    <br />
                                    We collect information about your activity
                                    on our services, for instance how you use
                                    them (e.g., date and time you logged in,
                                    features you’ve been using, searches, clicks
                                    and pages which have been shown to you,
                                    referring webpage address, advertising that
                                    you click on) and how you interact with
                                    other users (e.g., users you connect and
                                    interact with, time and date of your
                                    exchanges, number of messages you send and
                                    receive).
                                    <br />
                                    <br />
                                    Device information
                                    <br />
                                    We collect information from and about the
                                    device(s) you use to access our services,
                                    including:
                                    <br />
                                    <br />
                                    hardware and software information such as IP
                                    address, device ID and type, device-specific
                                    and apps settings and characteristics, app
                                    crashes, advertising IDs (such as Google’s
                                    AAID and Apple's IDFA, both of which are
                                    randomly generated numbers that you can
                                    reset by going into your device’ settings),
                                    browser type, version and language,
                                    operating system, time zones, identifiers
                                    associated with cookies or other
                                    technologies that may uniquely identify your
                                    device or browser (e.g., IMEI/UDID and MAC
                                    address);
                                    <br />
                                    information on your wireless and mobile
                                    network connection, like your service
                                    provider and signal strength;
                                    <br />
                                    information on device sensors such as
                                    accelerometers, gyroscopes and compasses.
                                    <br />
                                    <br />
                                    Other information with your consent
                                    <br />
                                    If you give us permission, we can collect
                                    your precise geolocation (latitude and
                                    longitude) through various means, depending
                                    on the service and device you’re using,
                                    including GPS, Bluetooth or Wi-Fi
                                    connections. The collection of your
                                    geolocation may occur in the background even
                                    when you aren’t using the services if the
                                    permission you gave us expressly permits
                                    such collection. If you decline permission
                                    for us to collect your geolocation, we will
                                    not collect it.
                                    <br />
                                    Similarly, if you consent, we may collect
                                    your photos and videos (for instance, if you
                                    want to publish a photo, video or streaming
                                    on the services).
                                </span>
                            </div>

                            <br />
                            <div>
                                <b>
                                    4. COOKIES AND OTHER SIMILAR DATA COLLECTION
                                    TECHNOLOGIES
                                </b>
                                <br />
                                <span>
                                    We use and may allow others to use cookies
                                    and similar technologies (e.g., web beacons,
                                    pixels) to recognize you and/or your
                                    device(s). You may read our Cookie Policy
                                    for more information on why we use them
                                    (such as authenticating you, remembering
                                    your preferences and settings, analyzing
                                    site traffic and trends, delivering and
                                    measuring the effectiveness of advertising
                                    campaigns, allowing you to use social
                                    features) and how you can better control
                                    their use, through your browser settings and
                                    other tools.
                                    <br />
                                    <br />
                                    Some web browsers (including Safari,
                                    Internet Explorer, Firefox and Chrome) have
                                    a “Do Not Track” (“DNT”) feature that tells
                                    a website that a user does not want to have
                                    his or her online activity tracked. If a
                                    website that responds to a DNT signal
                                    receives a DNT signal, the browser can block
                                    that website from collecting certain
                                    information about the browser’s user. Not
                                    all browsers offer a DNT option and DNT
                                    signals are not yet uniform. For this
                                    reason, many businesses, including
                                    Hottest Lab, do not currently respond to DNT
                                    signals.
                                </span>
                            </div>

                            <br />
                            <div>
                                <b>5. HOW WE USE INFORMATION</b>
                                <br />
                                <span>
                                    The main reason we use your information is
                                    to deliver and improve our services.
                                    Additionally, we use your info to help keep
                                    you safe and to provide you with advertising
                                    that may be of interest to you. Read on for
                                    a more detailed explanation of the various
                                    reasons we use your information, together
                                    with practical examples.
                                    <br />
                                    <br />
                                    To administer your account and provide our
                                    services to you
                                    <br />
                                    Create and manage your account
                                    <br />
                                    Provide you with customer support and
                                    respond to your requests
                                    <br />
                                    Complete your transactions
                                    <br />
                                    Communicate with you about our services,
                                    including order management and billing
                                    <br />
                                    <br />
                                    To help you connect with other users
                                    <br />
                                    Analyze your profile and that of other users
                                    to recommend meaningful connections
                                    <br />
                                    Show users’ profiles to one another
                                    <br />
                                    <br />
                                    To ensure a consistent experience across
                                    your devices
                                    <br />
                                    Link the various devices you use so that you
                                    can enjoy a consistent experience of our
                                    services on all of them. We do this by
                                    linking devices and browser data, such as
                                    when you log into your account on different
                                    devices or by using partial or full IP
                                    address, browser version and similar data
                                    about your devices to help identify and link
                                    them.
                                    <br />
                                    <br />
                                    To provide new Hottest Lab services to you
                                    <br />
                                    Register you and display your profile on new
                                    Hottest Lab features and apps
                                    <br />
                                    Administer your account on these new
                                    features and apps
                                    <br />
                                    <br />
                                    To serve you relevant offers and ads
                                    <br />
                                    Administer sweepstakes, contests, discounts
                                    or other offers
                                    <br />
                                    Develop, display and track content and
                                    advertising tailored to your interests on
                                    our services and other sites
                                    <br />
                                    Communicate with you by email, phone, social
                                    media or mobile device about products or
                                    services that we think may interest you
                                    <br />
                                    <br />
                                    To improve our services and develop new ones
                                    <br />
                                    Administer focus groups and surveys
                                    <br />
                                    Conduct research and analysis of users’
                                    behavior to improve our services and content
                                    (for instance, we may decide to change the
                                    look and feel or even substantially modify a
                                    given feature based on users’ behavior)
                                    <br />
                                    Develop new features and services (for
                                    example, we may decide to build a new
                                    interests-based feature further to requests
                                    received from users).
                                    <br />
                                    <br />
                                    To prevent, detect and fight fraud or other
                                    illegal or unauthorized activities
                                    <br />
                                    Address ongoing or alleged misbehavior on
                                    and off-platform
                                    <br />
                                    Perform data analysis to better understand
                                    and design countermeasures against these
                                    activities
                                    <br />
                                    Retain data related to fraudulent activities
                                    to prevent against recurrences
                                    <br />
                                    <br />
                                    To ensure legal compliance
                                    <br />
                                    Comply with legal requirements
                                    <br />
                                    Assist law enforcement
                                    <br />
                                    Enforce or exercise our rights, for example
                                    our Terms
                                    <br />
                                    <br />
                                    To process your information as described
                                    above, we rely on the following legal bases:
                                    <br />
                                    <br />
                                    Provide our service to you: Most of the
                                    time, the reason we process your information
                                    is to perform the contract that you have
                                    with us. For instance, as you go about using
                                    our service to build meaningful connections,
                                    we use your information to maintain your
                                    account and your profile, to make it
                                    viewable to other users and recommend other
                                    users to you.
                                    <br />
                                    Legitimate interests: We may use your
                                    information where we have legitimate
                                    interests to do so. For instance, we analyze
                                    users’ behavior on our services to
                                    continuously improve our offerings, we
                                    suggest offers we think might interest you,
                                    and we process information for
                                    administrative, fraud detection and other
                                    legal purposes.
                                    <br />
                                    Consent: From time to time, we may ask for
                                    your consent to use your information for
                                    certain specific reasons. You may withdraw
                                    your consent at any time by contacting us at
                                    the address provided at the end of this
                                    Privacy Policy.
                                </span>
                            </div>

                            <br />
                            <div>
                                <b>6. HOW WE SHARE INFORMATION</b>
                                <br />
                                <span>
                                    Since our goal is to help you make
                                    meaningful connections, the main sharing of
                                    users’ information is, of course, with other
                                    users. We also share some users’ information
                                    with service providers and partners who
                                    assist us in operating the services, with
                                    other partner companies and, in some cases,
                                    legal authorities. Read on for more details
                                    about how your information is shared with
                                    others.
                                    <br />
                                    <br />
                                    With other users
                                    <br />
                                    You share information with other users (and
                                    in the case of any sharing features
                                    available on Hottest Lab, the individuals or
                                    apps with whom a Hottest Lab user may choose
                                    to share you with) when you voluntarily
                                    disclose information on the service
                                    (including your public profile). Please be
                                    careful with your information and make sure
                                    that the content you share is stuff that
                                    you’re comfortable being publically viewable
                                    since neither you nor we can control what
                                    others do with your information once you
                                    share it.
                                    <br />
                                    <br />
                                    If you choose to limit the audience for all
                                    or part of your profile or for certain
                                    content or information about you, then it
                                    will be visible according to your settings.
                                    <br />
                                    <br />
                                    With our service providers and partners
                                    <br />
                                    We use third parties to help us operate and
                                    improve our services. These third parties
                                    assist us with various tasks, including data
                                    hosting and maintenance, analytics, customer
                                    care, marketing, advertising, payment
                                    processing and security operations.
                                    <br />
                                    <br />
                                    We may also share information with partners
                                    who distribute and assist us in advertising
                                    our services. For instance, we may share
                                    limited information on you in hashed,
                                    non-human readable form to advertising
                                    partners.
                                    <br />
                                    <br />
                                    We follow a strict vetting process prior to
                                    engaging any service provider or working
                                    with any partner. All of our service
                                    providers and partners must agree to strict
                                    confidentiality obligations.
                                    <br />
                                    <br />
                                    For corporate transactions
                                    <br />
                                    We may transfer your information if we are
                                    involved, whether in whole or in part, in a
                                    merger, sale, acquisition, divestiture,
                                    restructuring, reorganization, dissolution,
                                    bankruptcy or other change of ownership or
                                    control.
                                    <br />
                                    <br />
                                    When required by law
                                    <br />
                                    We may disclose your information if
                                    reasonably necessary: (i) to comply with a
                                    legal process, such as a court order,
                                    subpoena or search warrant, government / law
                                    enforcement investigation or other legal
                                    requirements; (ii) to assist in the
                                    prevention or detection of crime (subject in
                                    each case to applicable law); or (iii) to
                                    protect the safety of any person.
                                    <br />
                                    <br />
                                    To enforce legal rights
                                    <br />
                                    We may also share information: (i) if
                                    disclosure would mitigate our liability in
                                    an actual or threatened lawsuit; (ii) as
                                    necessary to protect our legal rights and
                                    legal rights of our users, business partners
                                    or other interested parties; (iii) to
                                    enforce our agreements with you; and (iv) to
                                    investigate, prevent, or take other action
                                    regarding illegal activity, suspected fraud
                                    or other wrongdoing.
                                    <br />
                                    <br />
                                    With your consent or at your request
                                    <br />
                                    We may ask for your consent to share your
                                    information with third parties. In any such
                                    case, we will make it clear why we want to
                                    share the information.
                                    <br />
                                    <br />
                                    We may use and share non-personal
                                    information (meaning information that, by
                                    itself, does not identify who you are such
                                    as device information, general demographics,
                                    general behavioral data, geolocation in
                                    de-identified form), as well as personal
                                    information in hashed, non-human readable
                                    form, under any of the above circumstances.
                                    We may also share this information with
                                    third parties (notably advertisers) to
                                    develop and deliver targeted advertising on
                                    our services and on websites or applications
                                    of third parties, and to analyze and report
                                    on advertising you see. We may combine this
                                    information with additional non-personal
                                    information or personal information in
                                    hashed, non-human readable form collected
                                    from other sources. More information on our
                                    use of cookies and similar technologies can
                                    be found in our Cookie Policy
                                </span>
                            </div>

                            <br />
                            <div>
                                <b>7. CROSS-BORDER DATA TRANSFERS</b>
                                <br />
                                <span>
                                    Sharing of information laid out in Section 6
                                    sometimes involves cross-border data
                                    transfers, for instance to South Korea and
                                    other jurisdictions. As an example, where
                                    the service allows for users to be located
                                    in the European Economic Area (“EEA”), their
                                    personal information is transferred to
                                    countries outside of the EEA. We use
                                    standard contract clauses approved by the
                                    European Commission or other suitable
                                    safeguard to permit data transfers from the
                                    EEA to other countries. Standard contractual
                                    clauses are commitments between companies
                                    transferring personal data, binding them to
                                    protect the privacy and security of your
                                    data
                                </span>
                            </div>

                            <br />
                            <div>
                                <b>8. YOUR RIGHTS</b>
                                <br />
                                <span>
                                    We want you to be in control of your
                                    information, so we have provided you with
                                    the following tools:
                                    <br />
                                    <br />
                                    Access / Update tools in the service. Tools
                                    and account settings that help you to
                                    access, rectify or delete information that
                                    you provided to us and that’s associated
                                    with your account directly within the
                                    service. If you have any question on those
                                    tools and settings, please contact our
                                    customer care team for help here.
                                    <br />
                                    Device permissions. Mobile platforms have
                                    permission systems for specific types of
                                    device data and notifications, such as phone
                                    book and location services as well as push
                                    notifications. You can change your settings
                                    on your device to either consent or oppose
                                    the collection of the corresponding
                                    information or the display of the
                                    corresponding notifications. Of course, if
                                    you do that, certain services may lose full
                                    functionality.
                                    <br />
                                    Deletion. You can delete your account by
                                    using the corresponding functionality
                                    directly on the service. We want you to be
                                    aware of your privacy rights. Here are a few
                                    key points to remember:
                                    <br />
                                    <br />
                                    Reviewing your information. Applicable
                                    privacy laws may give you the right to
                                    review the personal information we keep
                                    about you (depending on the jurisdiction,
                                    this may be called right of access, right of
                                    portability or variations of those terms).
                                    You can request a copy of your personal
                                    information by putting in such a request
                                    here.
                                    <br />
                                    Updating your information. If you believe
                                    that the information we hold about you is
                                    inaccurate or that we are no longer entitled
                                    to use it and want to request its
                                    rectification, deletion or object to its
                                    processing, please contact us here.
                                    <br />
                                    For your protection and the protection of
                                    all of our users, we may ask you to provide
                                    proof of identity before we can answer the
                                    above requests.
                                    <br />
                                    <br />
                                    Keep in mind, we may reject requests for
                                    certain reasons, including if the request is
                                    unlawful or if it may infringe on trade
                                    secrets or intellectual property or the
                                    privacy of another user. If you wish to
                                    receive information relating to another
                                    user, such as a copy of any messages you
                                    received from him or her through our
                                    service, the other user will have to contact
                                    our Privacy Officer to provide their written
                                    consent before the information is released.
                                    <br />
                                    <br />
                                    Also, we may not be able to accommodate
                                    certain requests to object to the processing
                                    of personal information, notably where such
                                    requests would not allow us to provide our
                                    service to you anymore. For instance, we
                                    cannot provide our service if we do not have
                                    your date of birth.
                                    <br />
                                    <br />
                                    Uninstall. You can stop all information
                                    collection by an app by uninstalling it
                                    using the standard uninstall process for
                                    your device. If you uninstall the app from
                                    your mobile device, the unique identifier
                                    associated with your device will continue to
                                    be stored. If you re-install the application
                                    on the same mobile device, we will be able
                                    to re-associate this identifier to your
                                    previous transactions and activities.
                                    <br />
                                    Accountability. In certain countries,
                                    including in the European Union, you have a
                                    right to lodge a complaint with the
                                    appropriate data protection authority if you
                                    have concerns about how we process your
                                    personal information. The data protection
                                    authority you can lodge a complaint with
                                    notably may be that of your habitual
                                    residence, where you work or where we are
                                    established.
                                </span>
                            </div>

                            <br />
                            <div>
                                <b>9. HOW WE PROTECT YOUR INFORMATION</b>
                                <br />
                                <span>
                                    We work hard to protect you from
                                    unauthorized access to or alteration,
                                    disclosure or destruction of your personal
                                    information. As with all technology
                                    companies, although we take steps to secure
                                    your information, we do not promise, and you
                                    should not expect, that your personal
                                    information will always remain secure.
                                    <br />
                                    <br />
                                    We regularly monitor our systems for
                                    possible vulnerabilities and attacks and
                                    regularly review our information collection,
                                    storage and processing practices to update
                                    our physical, technical and organizational
                                    security measures.
                                    <br />
                                    <br />
                                    We may suspend your use of all or part of
                                    the services without notice if we suspect or
                                    detect any breach of security. If you
                                    believe that your account or information is
                                    no longer secure, please notify us
                                    immediately help@hottestlab.com
                                </span>
                            </div>

                            <br />
                            <div>
                                <b>10. HOW LONG WE RETAIN YOUR INFORMATION</b>
                                <br />
                                <span>
                                    We keep your personal information only as
                                    long as we need it for legitimate business
                                    purposes (as laid out in Section 5) and as
                                    permitted by applicable law. To protect the
                                    safety and security of our users on and off
                                    our services, we implement a safety
                                    retention window of three months following
                                    account deletion. During this period,
                                    account information will be retained
                                    although the account will of course not be
                                    visible on the services anymore.
                                    <br />
                                    <br />
                                    In practice, we delete or anonymize your
                                    information upon deletion of your account
                                    (following the safety retention window) or
                                    after two years of continuous inactivity,
                                    unless:
                                    <br />
                                    <br />
                                    we must keep it to comply with applicable
                                    law (for instance, some “traffic data” is
                                    kept for one year to comply with statutory
                                    data retention obligations);
                                    <br />
                                    we must keep it to evidence our compliance
                                    with applicable law (for instance, records
                                    of consents to our Terms, Privacy Policy and
                                    other similar consents are kept for five
                                    years);
                                    <br />
                                    there is an outstanding issue, claim or
                                    dispute requiring us to keep the relevant
                                    information until it is resolved; or
                                    <br />
                                    the information must be kept for our
                                    legitimate business interests, such as fraud
                                    prevention and enhancing users' safety and
                                    security. For example, information may need
                                    to be kept to prevent a user who was banned
                                    for unsafe behavior or security incidents
                                    from opening a new account.
                                    <br />
                                    Keep in mind that even though our systems
                                    are designed to carry out data deletion
                                    processes according to the above guidelines,
                                    we cannot promise that all data will be
                                    deleted within a specific timeframe due to
                                    technical constraints.
                                </span>
                            </div>

                            <br />
                            <div>
                                <b>11. CHILDREN’S PRIVACY</b>
                                <br />
                                <span>
                                    Our services are restricted to users who are
                                    18 years of age or older. We do not permit
                                    users under the age of 18 on our platform
                                    and we do not knowingly collect personal
                                    information from anyone under the age of 18.
                                    If you suspect that a user is under the age
                                    of 18, please use the reporting mechanism
                                    available through the service.
                                </span>
                            </div>

                            <br />
                            <div>
                                <b>12. PRIVACY POLICY CHANGES</b>
                                <br />
                                <span>
                                    Because we’re always looking for new and
                                    innovative ways to help you build meaningful
                                    connections, this policy may change over
                                    time. We will notify you before any material
                                    changes take effect so that you have time to
                                    review the changes.
                                </span>
                            </div>

                            <br />
                            <div>
                                <b>13. HOW TO CONTACT US</b>
                                <br />
                                <span>
                                    If you have questions about this Privacy
                                    Policy, please contact below email.
                                    <br />
                                    <br />
                                    help@hottestlab.com
                                </span>
                            </div>
                        </div>
                    </PrivacyText>
                </SectionTextContainer>
            </InnerSection>
        </Wrapper>
    );
}

export default PrivacyPage;
